#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

img.cover {
    height: 13vw;
    margin: 0.5vw;
    min-height: 200px;
}

#game-cover-tooltip img.cover {
    margin: 0;
}

.tooltip-content {
    background-color: black;
    padding: 5px;
    margin: 0;
    z-index: 99;
}

.tooltip-cursor {
    cursor: pointer;
}

.data-list-panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
}

.data-list-panel-content {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 0 !important;
}

.gallery-panel-content {
    background: linear-gradient(#efefef 0%, #fff 25%) !important;
}

.cover-shadow {
    border: 1px solid white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5), 0 -2px 5px rgba(0, 0, 0, 0.5);
}

/* theme style customizations */

.p-datatable .p-datatable-thead > tr > th {
    background-color: #efefef !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.4rem 0.2rem !important;
}

div.p-multiselect.icon-only .p-multiselect-label-container {
    width: 0;
}

div.p-panel.data-list-panel > div.p-toggleable-content {
    flex-grow: 1;
    overflow: auto;
}